// Main Layout

html {
  scroll-behavior: smooth;
}

// colours

$darkblue : #3B3E53;
$lilac : #B5B7FB;
$pink:#E1B0F2;
$yellow:#E8E604;
$blue:#B4CFE3;
$grey:#E5EAF7;
$shadow:#242637;

* {color: $darkblue;}

a.next {
  display: block;
  width: 60px;
  margin: 40px auto 0; 
  opacity: .5;
  cursor: pointer;
  width: 100px;
  height: 50px;
}

a.next:active,a.next:focus,a.next:hover {
  opacity: 1
}

a.next img {
  display: block
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-regular-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: italic;
}

p, a, ul, li {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;

}
strong {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}


a.button {
  font-size: 1.1rem;
    font-weight: bold;
    background: $darkblue;
    border-radius: 10px;

    &:hover {background: $shadow;}
}

h1,h2,h3,h4 {
  font-family: freude,sans-serif;
  font-style: normal;
  font-weight: 400;
  color: $darkblue;
  text-rendering: optimizeLegibility
}

h3 {font-size: 1.3rem;
  max-width: 80%;}





#logo {
  width:400px;
  display: block;
  margin: 0 auto;
}
#loop {
  width:300px;
  display: block;
  margin: 80px auto -60px;
}



@media screen and (min-width: 0) {

  footer p {
    text-align:center;
  }

  #footright {text-align:center;
  
    ul {margin-left:0!important;
    
    li:first-child{margin-left:0!important;}}
  
  }
  #footleft {text-align:center;
  
   
  }

  section#hero {
    background:$darkblue;
    color:white;
    padding: 0 0 40px 0;
    text-align: center;
    background-image: url('/assets/img/90s.png');
    background-size: 220%;
    h1, h2 {font-size:1px;position:absolute; margin-right: -9999999999999999px;}
  }

#feature-block {
  width: 370px;
  aspect-ratio: 1;
  background-image: url(/assets/img/bgcircle.png);
  background-size: 90%;
  background-repeat: no-repeat;
  background-position: top;
  margin: 0 auto;
  padding: 120px 60px 0 120px;
  text-align: center;
  font-size: .7rem;
}

  img#choice {padding:0 40px;}

  img.addpadding {
    padding: 30px 130px;
}

  #top-info{
    padding: 3px 0;
    font-size: .8rem;
    display: block;
    margin: 0 auto;
    text-align: center;
    width:100%;
}

section {

  &#nextsteps {

    padding:80px 0;
  }
 
  &.grey {background-color: $grey; padding: 40px 0;}
  &.lilac {background-color: $lilac; padding: 40px 0 0 0;
  
  .option {margin-bottom:40px;}
  }
  &.light {padding:40px 0;}

  &#ae {padding-top:0;}

  &#appointment {padding-bottom:0!important;
  
    ul {padding-bottom:40px;}
  
  }

  h2 {font-size:24px;}
  h3 {font-size:20px;}

}

#offset-blocks {
  
  ul {
    margin:0;padding:0;
    
  li {
  list-style-type: none;
  padding: 15px 25px;
  margin-bottom: 5px;
  width:95%;
  
  &:nth-child(1){
    background-color: $darkblue;
    color:white;

  }
  &:nth-child(2){
    background-color: $blue;
margin-left:20px;
  }
  &:nth-child(3){
    background-color: $lilac;
margin-left:15px;
  }
  &:nth-child(4){
    background-color: $pink;
margin-left:30px;
  }
  
 

}}

}


}

@media screen and (min-width: 400px) {

  #feature-block  {
    width: 420px;
    aspect-ratio: 1;
    background-image: url(/assets/img/bgcircle.png);
    background-size: 90%;
    background-repeat: no-repeat;
    background-position: top;
    margin: 0 auto;
    padding: 130px 60px 0 120px;
    text-align: center;
    font-size: 0.8rem;
}


}

@media screen and (min-width: 460px) {
  img#choice {padding:0 80px;}

  section#hero {
    background:$darkblue;
    color:white;
    padding: 0 0 40px 0;
    text-align: center;
    background-image: url('/assets/img/90s.png');
    background-size: 180%;
    h1, h2 {font-size:1px;position:absolute; margin-right: -9999999999999999px;}
  }

}

@media screen and (min-width: 640px) {

  img.addpadding {
    padding: 10px;
}

footer p {
  text-align: inherit;
}

#footright {
  text-align:right;
}


#footleft {
  text-align:left;
}


}

@media screen and (min-width: 768px) {

  section#hero {
    background:$darkblue;
    color:white;
    padding: 0 0 40px 0;
    text-align: center;
    background-image: url('/assets/img/90s.png');
    background-size: contain;
    h1, h2 {font-size:1px;position:absolute; margin-right: -9999999999999999px;}
  }

  img.addpadding {
    padding: 30px;
}



}
@media screen and (min-width: 900px) {



}

@media screen and (min-width: 1024px) {

  img#choice {padding:0;}

  img.addpadding {padding:20px 60px;}

  section {  
    
    h2 {font-size:32px;}
  h3 {font-size:20px;}

  &.grey {background-color: $grey; padding:40px 0;}
  &.lilac {background-color: $lilac; }

}

}

@media screen and (min-width: 1440px) {

}

@media screen and (min-width: 1800px) {

}


// Footer

#side-effects {
  border: 3px solid $darkblue;
  padding: 20px;
  text-align: center;
  font-weight: bold;
  margin-top: 0px;

  a {text-decoration: underline; color:$darkblue }
}


footer {
  
  background: $darkblue;
  color: #fff;
  padding: 40px 0;
  font-size: .7rem;

  p {color: white !important;}
}




footer ul li {
  list-style-type: none;
  display: inline-block;
  margin-left: 20px
}

footer ul li a {
  color: #fff
}

footer ul li a:active,footer ul li a:focus,footer ul li a:hover {
  color: #745fb4
}

footer ul li a:active {
  color: #745fb4;
  text-decoration: underline
}

img.icon {
  width: 100px
}

.reveal {
  border-radius: 20px;
  padding: 40px;
  text-align: center;
}
